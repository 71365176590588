import { ChecklistOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { useBomItemIssueDescription } from 'features/BillOfMaterials/components/useBomItemIssueDescription'
import { bomItemAllActivitiesSelector } from 'features/BillOfMaterials/store/selectors/activities/allBomItemActivitiesSelector'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'

type Props = {
  bomItemPointer: BomItemPointer
  issue: IssueDto
}
export function MaterialNotSetTodo(props: Props) {
  const { getIssueDescription } = useBomItemIssueDescription()
  const allActivities = useAppSelector(
    bomItemAllActivitiesSelector(props.bomItemPointer),
    isEqual
  )

  const activitiesWithoutArticles = allActivities.filter(
    (x) => (x.usesRawMaterial || x.supportsArticle) && !x.article?.id
  )

  return (
    <Button
      component={'li'}
      style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      onClick={() => {
        document
          .getElementById(`txt_keywords_${activitiesWithoutArticles[0].id}`)
          ?.querySelector('input')
          ?.focus()
      }}
      color="inherit"
      startIcon={<ChecklistOutlined />}
    >
      <Typography variant="body2">
        {getIssueDescription(props.issue)}
      </Typography>
    </Button>
  )

  // return (
  //   <>
  //     <div
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         width: '100%',
  //         gap: '1rem',
  //       }}
  //     >
  //       {activitiesWithoutArticles.map((activity) => {
  //         return (
  //           <div
  //             key={activity.primaryWorkingStep}
  //             style={{
  //               display: 'flex',
  //               alignItems: 'flex-end',
  //               gap: '1rem',
  //             }}
  //           >
  //             <WorkingStepIconSvg
  //               workingStepType={activity.primaryWorkingStep}
  //               attributes={{ width: 28 }}
  //             />
  //             <BomItemActivityKeywordsInput
  //               activityId={activity.id}
  //               bomItemPointer={props.bomItemPointer}
  //               disableClickStopPropagation
  //               // disableLabel
  //             />
  //             <ActivityArticleSelectorButton
  //               bomItemPointer={props.bomItemPointer}
  //               activityId={activity?.id}
  //               workingStepType={activity?.primaryWorkingStep}
  //               variant="partTypeView"
  //               // setArticleDialogOpen={setArticleDialogOpen}
  //             />
  //           </div>
  //         )
  //       })}
  //     </div>

  //     <div style={{ paddingLeft: '43px' }}>
  //       <PurchasingItemSwitcher bomItemPointer={props.bomItemPointer} dialog />
  //     </div>
  //   </>
  // )
}
