import {
  CancelOutlined,
  CheckOutlined,
  RefreshOutlined,
} from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import {
  MRT_Localization,
  MRT_TableInstance,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table'
import { MRT_Localization_DE } from 'material-react-table/locales/de'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import { MRT_Localization_NL } from 'material-react-table/locales/nl'
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR'
import { useTranslation } from 'react-i18next'

type appTableOptions<TData> = MRT_TableOptions<TData> & {
  /**
   * will draw a refresh button in the top toolbar (left)
   */
  refresh?: {
    loading: boolean
    onRefresh: () => void
  }

  /*
   * will remove the column drag and drop icon (grouping will be enable in column actions)
   */
  enableColumnDragAndDrop?: boolean
}

function useLocalizedMaterialReactTable<TData>(
  options: appTableOptions<TData>
) {
  const { t, i18n } = useTranslation()

  let localization: MRT_Localization

  switch (i18n.language) {
    case 'de-DE':
      localization = MRT_Localization_DE
      break
    case 'fr-FR':
      localization = MRT_Localization_FR
      break
    case 'nl-NL':
      localization = MRT_Localization_NL
      break
    case 'pt-BR':
      localization = MRT_Localization_PT_BR
      break
    case 'en':
    default:
      localization = MRT_Localization_EN
      break
  }

  localization.search = t('common:search')

  let renderTopToolbarCustomActions = undefined

  if (options?.refresh) {
    renderTopToolbarCustomActions = (props: {
      table: MRT_TableInstance<TData>
    }) => {
      return (
        <div style={{ display: 'flex', gap: '1em' }}>
          <Tooltip title={t('common:refresh')}>
            <IconButton
              size="small"
              onClick={options.refresh.onRefresh}
              disabled={options.refresh.loading}
            >
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
          <div>
            {options.renderTopToolbarCustomActions?.({ table: props.table })}
          </div>
        </div>
      )
    }
  } else {
    renderTopToolbarCustomActions = options.renderTopToolbarCustomActions
  }

  const tableOptions = useMaterialReactTable<TData>({
    localization: localization,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    muiSearchTextFieldProps: {
      variant: 'outlined',
      ...(options?.muiSearchTextFieldProps || {}),
    },
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      ...(options?.initialState || {}),
    },
    enableColumnDragAndDrop: options.enableColumnDragAndDrop || false,
    muiTableHeadCellProps: {
      sx: {
        '& > div': {
          justifyContent: 'space-between',
        },
        '& .Mui-TableHeadCell-Content-Wrapper': {
          textTransform: 'lowercase',
        },
        '& [draggable=true]': {
          display:
            options.enableColumnDragAndDrop === false ? 'none' : 'inline-flex',
        },
        ...(options?.muiTableHeadCellProps?.['sx'] || {}),
      },
      ...(options?.muiTableHeadCellProps || {}),
    },
    muiToolbarAlertBannerProps: {
      sx: {
        backgroundColor: 'transparent',
        // borderTop: (theme) => `2px dashed ${theme.palette.primary.main}`,
        // borderBottom: (theme) => `2px dashed ${theme.palette.primary.main}`,
        // ...(options?.muiToolbarAlertBannerProps?.['sx'] || {}),
      },
      ...(options?.muiToolbarAlertBannerProps || {}),
    },
    positionActionsColumn: 'last',
    icons: {
      SaveIcon: () => <CheckOutlined color="success" />,
      CancelIcon: () => <CancelOutlined color="error" />,
    },
    ...options,
    renderTopToolbarCustomActions: renderTopToolbarCustomActions,
  })

  return tableOptions
}

export default useLocalizedMaterialReactTable
