import { BaseController } from 'controllers/BaseController'
import { debounce, DebouncedFuncLeading } from 'lodash'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import {
  GetProjectSummariesResponse,
  GetProjectSummariesResponsePaged,
  ProjectSummaryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  HttpGetMetaData,
  InternalAPI,
} from 'services/APIs/InternalAPI/InternalAPI'
import { GetPartyId } from 'store/storeUtils'

export interface IProjectsSummariesAPI {
  GetProjectsList(): Promise<GetProjectSummariesResponse>
  GetProjectsListPaged(
    onlyMyProjects?: boolean,
    showCanceledProjects?: boolean,
    meta?: HttpGetMetaData<ProjectSummaryDto>,
    projectStateFilter?: string[]
  ): Promise<GetProjectSummariesResponsePaged>
  DebouncedGetProjectListPaged: DebouncedFuncLeading<
    (
      onlyMyProjects?: boolean,
      meta?: HttpGetMetaData<ProjectSummaryDto>
    ) => Promise<GetProjectSummariesResponsePaged>
  >
  UpdateProjectStatus: (
    projectId: string,
    initialStatus: string,
    targetStatus: string,
    position: number
  ) => Promise<void>
  UpdateProjectPriority: (projectId: string, priority: number) => Promise<void>
}

export class ProjectSummariesAPI
  extends InternalAPI
  implements IProjectsSummariesAPI
{
  constructor(
    partyId: string,
    private onRequestChange: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/projects`)
  }
  DebouncedGetProjectListPaged: DebouncedFuncLeading<
    (
      onlyMyProjects?: boolean,
      meta?: HttpGetMetaData<ProjectSummaryDto>
    ) => Promise<GetProjectSummariesResponsePaged>
  >
  public async GetProjectsList(): Promise<GetProjectSummariesResponse> {
    return await this.GetAsync<GetProjectSummariesResponse>({
      id: 'GetProjectsList',
      relativePath: '',
      onRequestChange: this.onRequestChange,
    })
  }

  public async GetProjectsListPaged(
    onlyMyProjects?: boolean,
    showCanceledProjects?: boolean,
    meta?: HttpGetMetaData<ProjectSummaryDto>,
    projectStateFilter?: string[]
  ): Promise<GetProjectSummariesResponsePaged> {
    return await this.GetPagedAsync<GetProjectSummariesResponsePaged>({
      id: 'GetProjectsListPaged',
      relativePath: '/getProjectsPaged',
      onRequestChange: this.onRequestChange,
      _meta: meta,
      params: {
        onlyMyProjects: onlyMyProjects ?? false,
        showCanceledProjects: showCanceledProjects ?? false,
        projectStates:
          projectStateFilter?.length > 0 ? projectStateFilter : undefined,
      },
    })
  }

  public async UpdateProjectStatus(
    projectId: string,
    initialStatus: string,
    targetStatus: string,
    position: number
  ) {
    return await this.PutAsync<void>({
      id: `UpdateProjectStatus-${projectId}`,
      relativePath: `/${projectId}/status`,
      data: {
        initialStatus,
        targetStatus,
        position: position,
      },
      onRequestChange: this.onRequestChange,
    })
  }

  public async UpdateProjectPriority(projectId: string, priority: number) {
    return await this.PutAsync<void>({
      id: `UpdateProjectPriority-${projectId}`,
      relativePath: `/${projectId}/priority`,
      data: {
        priority: priority,
      },
      onRequestChange: this.onRequestChange,
    })
  }
}

export class ProjectsSummariesController extends BaseController<IProjectsSummariesAPI> {
  constructor() {
    super(
      (onRequestChanged) =>
        new ProjectSummariesAPI(GetPartyId(), onRequestChanged)
    )
  }

  public async GetProjectsList(): Promise<GetProjectSummariesResponse> {
    try {
      const response = await this.api.GetProjectsList()
      return response
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  private debouncedGetProjectListPaged = debounce(
    async (
      onlyMyProjects?: boolean,
      showCanceledProjects?: boolean,
      meta?: HttpGetMetaData<ProjectSummaryDto>,
      projectStateFilter?: string[]
    ): Promise<GetProjectSummariesResponsePaged> => {
      try {
        const response = await this.api.GetProjectsListPaged(
          onlyMyProjects,
          showCanceledProjects,
          meta,
          projectStateFilter
        )
        return response
      } catch (err) {
        throw this.HandleError(err)
      }
    },
    15000,
    { leading: true, trailing: true }
  )

  public override CancelRequests(): void {
    super.CancelRequests()
    this.debouncedGetProjectListPaged.cancel()
  }

  public async GetProjectsListPaged(
    onlyMyProjects?: boolean,
    showCanceledProjects?: boolean,
    meta?: HttpGetMetaData<ProjectSummaryDto>,
    projectStateFilters?: string[]
  ): Promise<GetProjectSummariesResponsePaged> {
    try {
      this.CancelRequests()
      this.debouncedGetProjectListPaged.cancel()

      const response = await this.debouncedGetProjectListPaged(
        onlyMyProjects,
        showCanceledProjects,
        meta,
        projectStateFilters
      )
      return response
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async UpdateProjectStatus(
    projectId: string,
    initialStatus: string,
    targetStatus: string,
    position: number
  ) {
    try {
      await this.api.UpdateProjectStatus(
        projectId,
        initialStatus,
        targetStatus,
        position
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async UpdateProjectPriority(projectId: string, priority: number) {
    try {
      await this.api.UpdateProjectPriority(projectId, priority)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
