import { ReportProblemOutlined } from '@mui/icons-material'
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { GetCurrentLanguageNumberPart } from 'components/Common/Input/NumberParts'
import { useRef } from 'react'
import {
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
} from 'react-number-format'

export type DefinitiveDecimalTextFieldProps = {
  value: number
  onChange: (value: number) => void
  numberFormatProps?: NumericFormatProps
  textFieldProps?: TextFieldProps
  disableSelectOnFocus?: boolean
  warnings?: string[]
  rootElementClassName?: string
  showRawValueInTooltip?: (value: number) => string
  disableWarningsIcon?: boolean
  maxValue?: number
  minValue?: number
  isAllowed?: (values: NumberFormatValues) => boolean
  selectTextOnFirstRender?: boolean
}

const DecimalTextFieldTooltipTitle = (props: {
  warnings: string[]
  rawValue: string | number
}) => {
  return (
    <div>
      {<div>{props.rawValue}</div>}
      {props.warnings?.length > 0 && (
        <div>
          <ul>
            {props.warnings.map((warning, index) => (
              <li key={index}>
                <Typography variant="caption">{warning}</Typography>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

function TextFieldWithAutoSelect(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      onFocus={(e) => {
        e.target.select()
      }}
      inputProps={{
        ...props.inputProps,
      }}
    />
  )
}

export const DefinitiveDecimalTextField = (
  props: DefinitiveDecimalTextFieldProps
) => {
  const showTooltip =
    (props.value && props.value.toString().length > 6) || props.warnings?.length
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Tooltip
      title={
        showTooltip && props.showRawValueInTooltip ? (
          <DecimalTextFieldTooltipTitle
            warnings={props.warnings}
            rawValue={
              props.showRawValueInTooltip
                ? props.showRawValueInTooltip(props.value)
                : props.value
            }
          />
        ) : (
          ''
        )
      }
    >
      <span className={props.rootElementClassName}>
        <NumericFormat
          {...props.numberFormatProps}
          inputRef={inputRef}
          thousandSeparator={
            props.numberFormatProps.thousandSeparator
              ? GetCurrentLanguageNumberPart('group')
              : false
          }
          decimalSeparator={GetCurrentLanguageNumberPart('decimal')}
          size={props.textFieldProps?.size}
          color={props.textFieldProps?.color}
          error={props.warnings?.length > 0}
          customInput={
            props.disableSelectOnFocus ? TextField : TextFieldWithAutoSelect
          }
          // eslint-disable-next-line
          {...(props.textFieldProps as any)}
          helperText={
            props.textFieldProps.helperText
              ? props.textFieldProps.helperText
              : props.warnings?.length > 0 &&
                props.disableWarningsIcon &&
                props.warnings.map((x) => (
                  <Typography
                    key={x}
                    variant={'caption'}
                    component="span"
                    style={{ whiteSpace: 'normal', display: 'block' }}
                  >
                    {x}
                  </Typography>
                ))
          }

          InputProps={{
            ...(props.textFieldProps?.InputProps || {}),
            startAdornment: props.textFieldProps?.InputProps?.startAdornment ? (
              <InputAdornment position="start">
                {props.textFieldProps.InputProps?.startAdornment}
                {props.warnings && !props.disableWarningsIcon && (
                  <Tooltip title={props.warnings.join('\n')}>
                    <span>
                      <ReportProblemOutlined color="secondary" />
                    </span>
                  </Tooltip>
                )}
              </InputAdornment>
            ) : (
              props.warnings &&
              !props.disableWarningsIcon && (
                <InputAdornment position="start">
                  <Tooltip title={props.warnings.join('\n')}>
                    <ReportProblemOutlined color="secondary" />
                  </Tooltip>
                </InputAdornment>
              )
            ),
            endAdornment: props.textFieldProps?.InputProps?.endAdornment && (
              <InputAdornment position="end">
                {props.textFieldProps.InputProps?.endAdornment}
              </InputAdornment>
            ),
          }}
          value={props.value}
          onValueChange={(values, sourceInfo) => {
            if (sourceInfo.source === 'event') {
              props.onChange(values.floatValue)
            }
          }}
          isAllowed={props.isAllowed}
        />
      </span>
    </Tooltip>
  )
}
