import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
} from 'material-react-table'
import { ChangeEvent } from 'react'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectStateSelector } from './ProjectStateSelector'

import { RefreshButton } from 'components/Common/Button/RefreshWithProgress'
import { t } from 'i18next'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'ProjectsToolbar' })((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(0.5),
    gap: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  refresh: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
}))

type Props = {
  table: MRT_TableInstance<ProjectSummaryDto>
  loading: Record<string, boolean>
  onlyMyProjects: boolean
  showCanceledProjects: boolean
  setShowCanceledProjects: (value: boolean) => void
  getProjectList: () => void
  handleChangeOnlyMyProjects: (
    ev: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  handleDeleteSelectedProjects: (
    table: MRT_TableInstance<ProjectSummaryDto>
  ) => Promise<void>
  selectedProjectStates: ProjectStateAsString[]
  onProjectStateChange: (selectedProjectStates: ProjectStateAsString[]) => void
}

const myProjectTitle = {
  buyer: {
    translationKey: 'common:my-projects-buyer',
    defaultText: 'shows only projects create by me',
  },
  seller: {
    translationKey: 'common:my-projects-seller',
    defaultText:
      'shows only project created by me or where I am the sales representative',
  },
}

export function TopToolbar(props: Props) {
  const { classes } = useStyles()

  const isBuyer = useAppSelector(
    (state) => state.user.organizationContext.isBuyingParty
  )

  return (
    <div className={classes.root}>
      <ProjectStateSelector
        onProjectStateChange={props.onProjectStateChange}
        selectedProjectStates={props.selectedProjectStates}
      ></ProjectStateSelector>
      <MRT_GlobalFilterTextField
        table={props.table}
        helperText={
          <Tooltip
            title={t(
              myProjectTitle[isBuyer ? 'buyer' : 'seller'].translationKey,
              myProjectTitle[isBuyer ? 'buyer' : 'seller'].defaultText
            )}
          >
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={props.onlyMyProjects}
                  onChange={props.handleChangeOnlyMyProjects}
                  style={{
                    padding: 0,
                    marginRight: '1em',
                  }}
                />
              }
              label={
                <Typography variant="caption">
                  {t('common:my-projects', 'my projects')}
                </Typography>
              }
            />
          </Tooltip>
        }
      />
      <div className={classes.refresh}>
        <RefreshButton
          size="small"
          onClick={props.getProjectList}
          loading={props.loading['GetProjectsListPaged']}
        />
        {/* <IconButton
          size="small"
          onClick={() => {
            props.getProjectList()
          }}
        >
          {props.loading['GetProjectsListPaged'] ? (
            <CircularProgress size={18} />
          ) : (
            <ReplayOutlined />
          )}
        </IconButton> */}
      </div>
      <MRT_ShowHideColumnsButton table={props.table} size="small" />
    </div>
  )
}
