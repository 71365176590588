/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, useTheme } from '@mui/material'
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { useWorkingStepFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { RemoveFiltersIcon } from 'features/BillOfMaterials/ProjectHeader/RemoveFiltersIcon'
import { MoneyAxisTick } from 'features/BomItemDetailsDrawer/Graph/YAxisTick'
import { isEqual } from 'lodash'
import { CSSProperties, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent'
import { getPriceSummaryKey } from 'store/Project/ProjectStateUtils'
import { projectConstants } from 'store/Project/ProjectStore'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { PriceSummaryDto } from '../../../services/APIs/InternalAPI/internal-api.contracts'
import { CostFactorLabel } from './CostFactorLabel'
import './CostPriceGraph.scss'
import { MarginEditor } from './MarginEditor'
import { MarginGraphTooltip } from './MarginGraphTooltip'

function RenderLegend(props: LegendProps) {
  const theme = useTheme()

  return (
    <ul
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
        marginTop: '1rem',
        flexWrap: 'wrap',
      }}
    >
      {props.payload.map((entry, index) => {
        if (entry.value.includes('children')) return null
        if (!entry.payload['activeBar']) return null

        const style: CSSProperties = {
          display: 'inner-block',
          width: props.iconSize,
          height: props.iconSize,
          backgroundColor: entry.color,
          marginRight: '0.5rem',
        }

        if (entry.payload['dataKey'] === 'discountValue.value') {
          style.backgroundColor = 'none'
          style.border = `1px solid ${theme.palette.error.main}`
          style.background = `repeating-linear-gradient(
            -45deg,
            ${'transparent'},
            ${'transparent'} 15%,
            ${theme.palette.error.main} 20%)
            `
        }

        return (
          <li
            key={`item-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: entry.color,
            }}
          >
            <div style={style} />
            <Typography variant="body2">{entry.value}</Typography>
          </li>
        )
      })}
    </ul>
  )
}

const ProjectCostPriceGraph = () => {
  const { t } = useTranslation()

  const data = useAppSelector(
    (state: RootAppState) =>
      Object.values(state.project.priceSummaries)
        .map((x) => ({
          ...x,
          discountValue: {
            ...x.discountValue,
            value: x.discountValue.value * -1,
          },
        }))
        .sort((a, b) => b.totalSalesPrice.value - a.totalSalesPrice.value),
    isEqual
  )

  const [selectedData, setSelectedData] = useState<PriceSummaryDto>(undefined)

  const currentTheme = useTheme()

  const isLocked = useAppSelector(
    (state: RootAppState) => state.project.activeProject.isLocked
  )

  const dispatch = useAppDispatch()

  const setData = (priceSummary: PriceSummaryDto) => {
    dispatch({
      type: projectConstants.SET_MARGIN_DATA,
      priceSummary,
    })
  }

  useEffect(() => {
    if (selectedData) {
      setSelectedData(
        data[
          data.findIndex(
            (x) => getPriceSummaryKey(x) === getPriceSummaryKey(selectedData)
          )
        ]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleChangeFilter: handleChangeMaterialFilter } =
    useArticleIdFilter()
  const { handleChangeFilter: handleChangeWSFilter } = useWorkingStepFilter()

  const handleSetSelectedData = (nextState: CategoricalChartState) => {
    if (!nextState) return

    const priceSummary = data[nextState?.activeTooltipIndex]

    if (!priceSummary) return

    setSelectedData(priceSummary)

    setTimeout(() => {
      if (priceSummary?.isWorkingStep) {
        handleChangeWSFilter(priceSummary.workingStep.primaryWorkingStep, true)
      } else if (priceSummary?.isMaterial) {
        handleChangeMaterialFilter([priceSummary.materialId])
      }
    }, 300)
  }

  return (
    <div className="cost-summary">
      <div className="cost-graph--container">
        <div style={{ position: 'absolute', top: 0, left: '1em', zIndex: 1 }}>
          <RemoveFiltersIcon />
        </div>
        <div
          className="graph"
          style={{
            height: `${data.length * 70}px`,
            minHeight: '220px',
            position: 'relative',
          }}
        >
          <ResponsiveContainer height={'100%'}>
            <BarChart
              data={data}
              layout="vertical"
              barCategoryGap={16}
              barGap={8}
              barSize={32}
              margin={{
                left: 16,
                right: 16,
              }}
              onClick={handleSetSelectedData}
              style={{ cursor: 'pointer' }}
            >
              <defs>
                <pattern
                  id="pattern-stripe"
                  width="4"
                  height="4"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(45)"
                >
                  <rect
                    width="1"
                    height="4"
                    transform="translate(0,0)"
                    fill="white"
                  ></rect>
                </pattern>
                <mask id="mask-stripe">
                  <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    fill="url(#pattern-stripe)"
                  />
                </mask>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                content={(props) => <MarginGraphTooltip {...props} />}
                cursor={true}
                contentStyle={{
                  zIndex: 10,
                  backgroundColor: 'white',
                }}
              />
              <Bar
                dataKey="totalCostPrice.value"
                name={t('project:total-cost-price', 'total cost price')}
                stackId="a"
                className="barInfo"
                fill={currentTheme.palette.info.main}
                onClick={(e) => {
                  setSelectedData(e)
                }}
                height={16}
              >
                <Cell
                  cursor="pointer"
                  overflow={'scroll'}
                  // onClick={(e) => setSelectedData(e)}
                />
                {/* <LabelList dataKey="name" position="left" /> */}
              </Bar>
              <Bar
                dataKey="surchargeValue.value"
                name={t('common:surcharge')}
                stackId="a"
                fill={currentTheme.palette.success.main}
                onClick={(e) => {
                  setSelectedData(e)
                }}
              />
              <Bar
                dataKey="discountValue.value"
                name={t('common:discount')}
                stackId="a"
                fill={currentTheme.palette.secondary.main}
                onClick={(e: PriceSummaryDto) => {
                  setSelectedData(e)
                }}
                mask="url(#mask-stripe)"
              >
                {data.map((entry, index) => {
                  if (!entry) return null
                  return (
                    <Cell
                      cursor="pointer"
                      strokeWidth={
                        selectedData &&
                        entry?.description === selectedData?.description
                          ? 1
                          : 0
                      }
                      stroke="black"
                      fill={currentTheme.palette.secondary.main}
                      mask="url(#mask-stripe)"
                      key={`cell-${index}`}
                    />
                  )
                })}
              </Bar>
              <XAxis
                type="number"
                orientation="top"
                ticks={data.map((x) => x.totalSalesPrice.value)}
                tick={<MoneyAxisTick defaultMoney={data[0].totalCostPrice} />}
                minTickGap={0}
                interval={'preserveStartEnd'}
                allowDataOverflow={true}
                domain={[0, 'dataMax']}
              />
              <YAxis
                dataKey="description"
                type="category"
                axisLine={true}
                stroke={'2px solid black'}
                tick={(props) => (
                  <CostFactorLabel
                    {...props}
                    priceSummaries={data}
                    selectedData={selectedData}
                  />
                )}
                width={240}
                tickMargin={32}
                minTickGap={0}
              />

              {/* <Legend verticalAlign="top" layout="horizontal" align="center" /> */}

              <Legend
                verticalAlign="top"
                layout="horizontal"
                align="center"
                content={(legendProps) => <RenderLegend {...legendProps} />}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="margin-editor-container">
          <MarginEditor
            selectedData={selectedData}
            setData={setData}
            setSelectedData={setSelectedData}
            disabled={isLocked}
          />
        </div>
      </div>
    </div>
  )
}

export { ProjectCostPriceGraph as CostPriceGraph }
