import { createAsyncThunk } from '@reduxjs/toolkit'
import { ToggleNewItemsModal } from 'store/Application/appActions'
import { MaterialActions } from 'store/MaterialSelector/MaterialActions'
import { UIActions } from 'store/UI/UIActions'
import { RootAppState } from 'store/configureStore'
import { newProjectActions } from '../projectReducer'
import { cancelFetchProject } from './fetchProject'
import { unsubscribeFromActiveProjectUpdates } from './subscribeToUpdates'

export const fullUnloadActiveProject = createAsyncThunk<
  void,
  null,
  { state: RootAppState }
>('project/fullUnload', async (_args, thunkAPI) => {
  const currentProjectId = thunkAPI.getState().project.activeProject?.id

  if (currentProjectId) {
    cancelFetchProject()

    const isBuyerParty =
      thunkAPI.getState().user.organizationContext.isBuyingParty

    thunkAPI.dispatch(newProjectActions.unloadProject())
    await thunkAPI.dispatch(
      unsubscribeFromActiveProjectUpdates({
        projectId: currentProjectId,
        isBuyingPartyView: isBuyerParty,
      })
    )
    thunkAPI.dispatch(ToggleNewItemsModal(false))
    thunkAPI.dispatch(MaterialActions.clipboardClear())
    thunkAPI.dispatch(UIActions.CloseModal())
  }
})
