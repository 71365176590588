import { AppModule } from 'services/APIs/InternalAPI/internal-api.contracts'
import { AppModuleFeatures } from './AppFeature'
import { FeatureKeys } from './FeatureKeys'

export const AppFeaturesDescription: AppModuleFeatures = {
  [FeatureKeys.kanbanBoard]: {
    description: 'Kanban Board',
    isActive: true,
    modules: [AppModule.SurfaceTreatment, AppModule.Enterprise],
  },
  [FeatureKeys.quotation]: {
    description: 'Prices',
    isActive: true,
    modules: [AppModule.SurfaceTreatment, AppModule.Enterprise],
  },
  [FeatureKeys.projectStateManagement]: {
    description: 'Project State Management',
    isActive: true,
    modules: [AppModule.SurfaceTreatment, AppModule.Enterprise],
  },
  [FeatureKeys.salesRepresentative]: {
    description: 'Sales Representative',
    isActive: true,
    modules: [AppModule.SurfaceTreatment, AppModule.Enterprise],
  },
  [FeatureKeys.bomItemDetails]: {
    description: 'BOM Item Details',
    isActive: true,
    modules: [AppModule.SurfaceTreatment, AppModule.Enterprise],
  },
  [FeatureKeys.bomView]: {
    description: 'BOM View',
    isActive: true,
    modules: [
      AppModule.BoMManager,
      AppModule.SurfaceTreatment,
      AppModule.Enterprise,
    ],
  },
  [FeatureKeys.routesView]: {
    description: 'Routes View',
    isActive: true,
    modules: [AppModule.SurfaceTreatment, AppModule.Enterprise],
  },
  [FeatureKeys.materialsView]: {
    description: 'Materials View',
    isActive: true,
    modules: [AppModule.SurfaceTreatment, AppModule.Enterprise],
  },
  [FeatureKeys.mixPanelDashboard]: {
    description: 'MixPanel Dashboard',
    isActive: true,
    modules: [AppModule.Enterprise],
  },
  [FeatureKeys.portal]: {
    description: 'Portal',
    isActive: true,
    modules: [AppModule.Enterprise],
  },
  [FeatureKeys.dashboardView]: {
    description: 'Dashboard View',
    isActive: true,
    modules: [AppModule.Enterprise],
  },
  [FeatureKeys.integrations]: {
    description: 'Integrations',
    isActive: true,
    modules: [AppModule.Enterprise],
  },
}
