import {
  CheckOutlined,
  ErrorOutlined,
  WarningOutlined,
} from '@mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material'
import { CancelButton } from 'components/Common/Button/CancelButton'
import useLocalizedMaterialReactTable from 'components/ProjectsPage/useLocalizedMaterialReactTable'
import { useAppController } from 'customHooks/useAppController'
import _ from 'lodash'
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import {
  EventLogDto,
  EventLogSeverityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { EventLogsController } from '../EventLogsController'

type EventLogType = 'manufacturability' | 'erp'

export type EventLogsDialogProps = {
  eventLogType?: EventLogType
  bomItemPointer: BomItemPointer
  open?: boolean
  onClose: () => void
}

const page_title = 'event logs'

const sampleData = [
  {
    category: 'category',
    severity: EventLogSeverityDto.Information,
    message: 'message',
    dateTime: new Date(),
    channel: 'channel',
    source: 'source',
  },
  {
    category: 'category2',
    severity: EventLogSeverityDto.Error,
    message: 'message',
    dateTime: new Date(),
    channel: 'channel',
    source: 'source',
  },
  {
    category: 'category2',
    severity: EventLogSeverityDto.Error,
    message: 'message',
    dateTime: new Date(),
    channel: 'channel',
    source: 'source',
  },
  {
    category: 'category',
    severity: EventLogSeverityDto.Error,
    message: 'message',
    dateTime: new Date(),
    channel: 'channel',
    source: 'source',
  },

  {
    category: 'category3',
    severity: EventLogSeverityDto.Error,
    message: 'message',
    dateTime: new Date(),
    channel: 'channel',
    source: 'source',
  },
]

const SeverityIcon = (props: { severity: EventLogDto['severity'] }) => {
  const theme = useTheme()

  switch (props.severity) {
    case EventLogSeverityDto.Information:
      return (
        <CheckOutlined
          htmlColor={theme.palette.info.main}
          style={{ alignSelf: 'center' }}
        />
      )
    case EventLogSeverityDto.Warning:
      return <WarningOutlined htmlColor={theme.palette.warning.main} />
    case EventLogSeverityDto.Error:
      return <ErrorOutlined htmlColor={theme.palette.error.main} />
    default:
      return <>{props.severity}</>
  }
}

export const EventLogsDialog = (props: EventLogsDialogProps) => {
  const [events, setEvents] = useState<Array<EventLogDto>>([])
  const { controller, loading } = useAppController(
    () => new EventLogsController()
  )
  useEffect(() => {
    ;(async () => {
      try {
        const getEventLogs = (eventType: EventLogType) =>
          controller.GetEventLogs(eventType, props.bomItemPointer)

        setEvents(
          _.flatten(
            await Promise.all([
              getEventLogs('erp'),
              getEventLogs('manufacturability'),
            ])
          )
        )

        // setEvents([...erpLogs, ...manufactLogs])
      } catch (err) {
        ShowException(page_title, err)
      }
    })()
  }, [controller, props.bomItemPointer, props.eventLogType])

  const columns = useMemo<MRT_ColumnDef<EventLogDto>[]>(() => {
    return [
      {
        header: 'category',
        accessorFn: (data) => data.category,
        size: 100,
        minSize: 50,
        id: 'category',
      },
      {
        header: 'severity',
        accessorFn: (data) => data.severity,
        size: 100,
        minSize: 50,
        id: 'severity',
        Cell: (data) => {
          return <SeverityIcon severity={data.row.original.severity} />
        },
      },
      {
        header: 'message',
        accessorFn: (data) => data.message,
        size: 150,
        minSize: 50,
        id: 'message',
      },
      {
        header: 'date',
        accessorFn: (data) => moment(data.dateTime).format('L LTS'),
        size: 100,
        minSize: 50,
        id: 'dateTime',
        Cell: (data) => {
          return (
            <Typography variant="body2">{data.renderedCellValue}</Typography>
          )
        },
      },
      {
        header: 'channel',
        accessorFn: (data) => data.channel,
        size: 100,
        minSize: 50,
        id: 'channel',
      },
      {
        header: 'source',
        accessorFn: (data) => data.source,
        size: 100,
        minSize: 50,
        id: 'source',
      },
    ]
  }, [])

  const table = useLocalizedMaterialReactTable({
    columns: columns,
    data: events,
    initialState: {
      grouping: ['category'],
    },
    state: {
      isLoading: loading['get-event-logs'],
    },
    enableColumnDragAndDrop: false,
    layoutMode: 'grid',
    enableSorting: false,
    enableGrouping: true,
    enableTopToolbar: true,
    enablePagination: true,
    enableBottomToolbar: false,
    enableGlobalFilter: false,
    enableHiding: false,
  })

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Event Logs</DialogTitle>
      <MaterialReactTable table={table} />
      {/* <MaterialTable
        localization={materialTableLocalization}
        title={
          <LocalizedTypography
            translationKey={'common:event-logs-title'}
            variant="h6"
          >
            Event Logs
          </LocalizedTypography>
        }
        options={{
          search: false,
          grouping: true,
          showEmptyDataSourceMessage: true,
          emptyRowsWhenPaging: false,
          maxBodyHeight: '70vh',
          defaultExpanded: true,
          defaultOrderByCollection: [],
        }}
        isLoading={loading['get-event-logs']}
        data={events}
        columns={[
          {
            field: 'category',
            title: 'category',
            defaultGroupOrder: 0,
            groupRender: (groupKey) => {
              return (
                <LocalizedTypography
                  translationKey={`common:event-log-category-${groupKey}`}
                  translationDefaultValue={groupKey as string}
                >
                  {groupKey}
                </LocalizedTypography>
              )
            },
            render: (rowData) => {
              return rowData.category
            },
          },
          {
            field: 'severity',
            title: 'severity',
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: (_, rowData) => {
              return {
                textAlign: rowData ? 'center' : 'left',
              }
            },
            groupRender: (groupKey) => {
              return (
                <LocalizedTypography
                  translationKey={`common:severity-${groupKey}`}
                >
                  {groupKey}
                </LocalizedTypography>
              )
            },
            render: (data) => {
              return <SeverityIcon severity={data.severity} />
            },
          },
          {
            field: 'message',
            title: 'message',
            grouping: false,
            width: '80%',
            headerStyle: {
              width: '80%',
            },
          },
          {
            title: 'date',
            field: 'dateTime',
            type: 'datetime',
            defaultSort: 'desc',
            headerStyle: {
              textAlign: 'center',
            },
          },
          {
            field: 'channel',
            title: 'channel',
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
          },
          {
            field: 'source',
            title: 'source',
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
          },
        ]}
      /> */}
      {/* <pre>{JSON.stringify(eventLogs, null, 2)}</pre> */}
      <DialogActions>
        <CancelButton
          onClick={props.onClose}
          labelTranslationKey={'common:close'}
        />
      </DialogActions>
    </Dialog>
  )
}
