import { DeleteOutline, OpenInNewOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { InProductionProjectStatuses } from 'features/BillOfMaterials/components/ProjectStatus/InProductionProjectStatuses'
import { t } from 'i18next'
import { MRT_Cell, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'

type Props = {
  cell: MRT_Cell<ProjectSummaryDto>
  row: MRT_Row<ProjectSummaryDto>
  staticRowIndex?: number
  table: MRT_TableInstance<ProjectSummaryDto>
  handleDeleteProject(projectId: string, status: ProjectStateAsString): void
}

export function RowActionsComponent(rowActionsProps: Props) {
  const doNotRenderDelete = InProductionProjectStatuses.includes(
    rowActionsProps.row.original.status.toUpperCase() as ProjectStateAsString
  )

  return (
    <>
      {doNotRenderDelete ? (
        <div style={{ width: '3em' }} />
      ) : (
        <Tooltip
          title={t('common:delete-project', 'delete project')}
          placement="left"
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              rowActionsProps.handleDeleteProject(
                rowActionsProps.row.original.id,
                rowActionsProps.row.original.status.toUpperCase() as ProjectStateAsString
              )
            }}
          >
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title={t('common:open-project-new-tab', 'open project in new tab')}
        placement="left"
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            window.open(
              `${window.location.origin}/app/project/${rowActionsProps.row.original.id}`,
              '_blank'
            )
          }}
        >
          <OpenInNewOutlined />
        </IconButton>
      </Tooltip>
    </>
  )
}
