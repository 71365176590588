import { Box, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import ValueLabel from 'components/Common/ValueLabel/ValueLabel'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { WorkingStepsController } from 'controllers/WorkingStepsController'
import { useAppController } from 'customHooks/useAppController'
import { BomItemDialogTitle } from 'features/BillOfMaterials/components/BomItemDialogTitle'
import { fetchCurrentProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { ConfirmationDialog } from 'features/ConfirmationDialog/ConfirmationDialog'
import {
  memo,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { alphabetcalSort } from 'utils/customSort'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import WorkingStepForm, { WorkingStepFormRef } from '../Form/WorkingStepForm'
import {
  WorkingStepWithData,
  initialState,
  reducer,
} from '../store/AdditionalWorkingStepsReducer'
import { AdditionalWorkingStepsDialogProps } from './AdditionalWorkingStepsDialogProps'
import WorkingStepList from './WorkingStepList'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    height: '70vh',
    ['& > div']: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: '100%',
      ['& > div']: {
        borderRight: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  open: {
    width: '100%',
  },
  addWorkingStepsColumn: {
    display: 'flex',
    flex: '1 1 25%',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'width .3s ease-in-out',
    borderRight: '1px solid black',
    position: 'relative',
  },
}))

const ListTitle = memo(
  (props: { translationKey: string; defaultValue: string }) => {
    return (
      <LocalizedTypography
        variant="body2"
        color="textSecondary"
        component="p"
        sx={{
          padding: (theme) => theme.spacing(0, 2),
          textAlign: 'right',
        }}
        translationKey={props.translationKey}
        defaultValue={props.defaultValue}
      />
    )
  }
)

ListTitle.displayName = 'ListTitle'

const EmptyList = memo(() => {
  return (
    <LocalizedTypography
      translationKey="project:additional-working-steps-included-no-results"
      variant="body1"
      color="textSecondary"
      sx={{
        width: '100%',
        height: '40%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
      }}
    >
      no included working steps
    </LocalizedTypography>
  )
})

EmptyList.displayName = 'EmptyList'

export function translateWorkingSteps(
  workingSteps: WorkingStepDto[],
  getLocalizedWorkingStep: (workingStep: WorkingStepType) => string
): WorkingStepWithData[] {
  return workingSteps
    .map((item) =>
      Object.assign(item, {
        label: getLocalizedWorkingStep(item.primaryWorkingStep),
      })
    )
    .sort(alphabetcalSort('label'))
}

export const AdditionalWorkingStepsDialog = (
  props: AdditionalWorkingStepsDialogProps
) => {
  const { t } = useTranslation()
  const { projectId } = useOrganizationAndProjectContext()
  const workingStepFormRef = useRef<WorkingStepFormRef>(null)
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()

  const [state, dispatch] = useReducer(reducer, initialState)
  const appDispatch = useAppDispatch()

  const { controller, loading } = useAppController(
    () => new WorkingStepsController()
  )
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const projectEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const { classes, cx } = useStyles()

  useEffect(() => {
    if (props.activeWorkingStep) {
      dispatch({
        type: 'setCurrentWorkingStep',
        payload: props.activeWorkingStep,
      })
    }
  }, [props.activeWorkingStep])

  useEffect(() => {
    controller
      .GetWorkingSteps(projectId, props.bomItemPointers[0].id)
      .then((res) => {
        if (!res) {
          return
        }

        const availableWorkingSteps = translateWorkingSteps(
          res.availableWorkingSteps,
          getLocalizedWorkingStep
        )

        const selectedWorkingSteps = translateWorkingSteps(
          res.selectedWorkingSteps,
          getLocalizedWorkingStep
        ).sort(alphabetcalSort('label'))

        dispatch({
          type: 'setAvailableWorkingSteps',
          payload: availableWorkingSteps,
        })

        dispatch({
          type: 'setSelectedWorkingSteps',
          payload: selectedWorkingSteps,
        })

        dispatch({
          type: 'setOriginalWorkingSteps',
          payload: selectedWorkingSteps,
        })
      })
      .catch((reason) =>
        ShowException(t('project:additional-working-steps--title'), reason)
      )

    return () => controller.CancelRequests()
  }, [controller, getLocalizedWorkingStep, projectId, props.bomItemPointers, t])

  const handleNewWorkingStepSelected = useCallback(
    (workingStep: WorkingStepDto) => {
      if (Boolean(workingStep)) {
        dispatch({ type: 'setWorkingStepsToAdd', payload: workingStep })
      }
    },
    []
  )

  const handleClose = useCallback(() => {
    if (
      !projectEditable ||
      (state.workingStepsToAdd.length === 0 &&
        state.workingStepsToRemove.length === 0)
    ) {
      props.onClose()
    } else {
      setConfirmationDialogOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectEditable,
    props.onClose,
    state.workingStepsToAdd.length,
    state.workingStepsToRemove.length,
  ])

  const handleSave = useCallback(() => {
    if (
      !state.workingStepsToAdd?.length &&
      !state.workingStepsToRemove?.length
    ) {
      props.onClose()
    } else {
      controller
        .upInsertAndRemoveWorkingStepsWithData(
          props.bomItemPointers,
          state.workingStepsToAdd,
          state.workingStepsToRemove
        )
        .then(() => {
          dispatch({
            type: 'resetWorkingStepsToAddAndRemove',
          })

          workingStepFormRef.current?.reload()
        })
        .then(() => {
          appDispatch(fetchCurrentProject())
        })
        .catch((reason) =>
          ShowException(t('project:additional-working-steps--title'), reason)
        )
    }
  }, [
    appDispatch,
    controller,
    props,
    state.workingStepsToAdd,
    state.workingStepsToRemove,
    t,
  ])

  const hasChanges =
    state.workingStepsToAdd.length > 0 || state.workingStepsToRemove.length > 0

  return (
    <Dialog open={props.open} maxWidth="xl" fullWidth onClose={handleClose}>
      <ConfirmationDialog
        title={t('project:additional-working-steps-title')}
        message={t('common:continue-without-saving')}
        onConfirm={props.onClose}
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      />
      <DialogTitle>
        <BomItemDialogTitle
          dialogTitle={t('project:additional-working-steps--title')}
          bomItemPointers={props.bomItemPointers}
        />
      </DialogTitle>
      <div className={classes.root}>
        <LoadingContainer
          loading={
            loading[WorkingStepsController.WorkingStepsOperations.LOAD_DATA]
          }
          noMessage
        >
          <div
            className={cx(classes.addWorkingStepsColumn, {
              [classes.open]: state.addWorkingStepOpen,
            })}
          >
            <WorkingStepList
              workingSteps={state.availableWorkingSteps}
              onWorkingStepSelected={handleNewWorkingStepSelected}
              listTitle={
                <ListTitle
                  translationKey="project:additional-working-steps-available-working-steps"
                  defaultValue="available working steps"
                />
              }
              emptyListMessage={
                <LocalizedTypography
                  translationKey="project:additional-working-steps-available-no-results"
                  variant="body1"
                  color="textSecondary"
                  sx={{
                    width: '100%',
                    height: '40%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end',
                  }}
                >
                  no results
                </LocalizedTypography>
              }
            />
          </div>
          <Box
            sx={{
              width: '100%',
              overflow: 'auto',
              maxWidth: (theme) => theme.spacing(50),
            }}
          >
            <WorkingStepList
              workingSteps={state.editableWorkingSteps}
              workingStepsWithErrors={state.workingStepsWithErrors}
              onWorkingStepSelected={(workingStep) => {
                dispatch({
                  type: 'setCurrentWorkingStep',
                  payload: workingStep,
                })
              }}
              highlightWorkingStep={
                state.currentWorkingStep?.primaryWorkingStep
              }
              onWorkingStepRemoved={(workingStep) => {
                dispatch({
                  type: 'setWorkingStepsToRemove',
                  payload: workingStep,
                })
              }}
              listTitle={
                <ListTitle
                  translationKey="project:additional-working-steps-included-working-steps"
                  defaultValue="included working steps"
                />
              }
              emptyListMessage={<EmptyList />}
            />
          </Box>
          <div style={{ width: '100%', height: '100%' }}>
            {state.currentWorkingStep && (
              <WorkingStepForm
                ref={workingStepFormRef}
                bomItemPointer={props.bomItemPointers[0]}
                workingStepWithData={state.currentWorkingStep}
                onWorkingStepChanged={(workingStepWithData) => {
                  dispatch({
                    type: 'updateWorkingStep',
                    payload: workingStepWithData,
                  })
                }}
                onValidationError={(
                  workingStep: WorkingStepWithData,
                  errorSchema: Record<string, { __errors: string[] }>
                ) => {
                  dispatch({
                    type: 'updateWorkingStepsWithErrors',
                    payload: {
                      workingStepType: workingStep.primaryWorkingStep,
                      errors: errorSchema,
                    },
                  })
                }}
              />
            )}
          </div>
        </LoadingContainer>
      </div>
      <DialogActions>
        <DialogCloseSaveButtons
          onSaveButtonClicked={handleSave}
          saving={
            loading[WorkingStepsController.WorkingStepsOperations.SAVE_DATA]
          }
          saveButtonTranslationKey={hasChanges ? 'common:save' : 'common:close'}
          saveButtonFormId="working-step-form"
          onCloseButtonClicked={handleClose}
          cancelButtonInvisible={!hasChanges}
          saveButtonDisabled={!projectEditable}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <ValueLabel
              label={t('project:additional-working-steps--to-add')}
              value={state.workingStepsToAdd?.length || 0}
            />
            <ValueLabel
              label={t('project:additional-working-steps--to-remove')}
              value={state.workingStepsToRemove?.length || 0}
            />
          </div>
        </DialogCloseSaveButtons>
      </DialogActions>
    </Dialog>
  )
}
