import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ProjectsSummariesController } from 'controllers/Projects/ProjectsSummariesController'
import { useAppController } from 'customHooks/useAppController'
import { useClientStorage } from 'customHooks/useClientStorage'
import { fullUnloadActiveProject } from 'features/BillOfMaterials/store/asyncActions/fullUnloadActiveProject'
import { SignalRService } from 'features/SignalR/service/SignalRService'
import {
  MaterialReactTable,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_VisibilityState,
} from 'material-react-table'
import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { deleteProjects } from 'store/ProjectList/asyncActions/deleteProjects'
import { useShowException } from 'utils/useShowException'
import useLocalizedMaterialReactTable from '../useLocalizedMaterialReactTable'
import { useProjectListColumns } from './_projectListColumns'
import { RowActionsComponent } from './components/RowActions'
import { TopToolbar } from './components/TopToolbar'

export const NewProjectList = () => {
  const { controller, loading } = useAppController(
    () => new ProjectsSummariesController()
  )
  const partyId = useAppSelector((state) => state.user?.organizationContext?.id)

  const ShowException = useShowException('projects')
  const [onlyMyProjects, setOnlyMyProjects] = useClientStorage<boolean>(
    'filterMyProjects',
    false
  )
  const [showCanceledProjects, setShowCanceledProjects] =
    useClientStorage<boolean>('showCanceledProjects', false)

  const _columns = useProjectListColumns()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [projectList, setProjectList] = useState<ProjectSummaryDto[]>([])

  const [currentQuery, setCurrentQuery] = useClientStorage('currentQuery', '')

  const [pagination, setPagination] = useClientStorage<MRT_PaginationState>(
    'project-list-pagination',
    {
      pageIndex: 0,
      pageSize: 20,
    },
    'localStorage',
    (oldValue) => {
      if (oldValue?.pageSize > 20) {
        return {
          ...oldValue,
          pageIndex: 0,
          pageSize: 20,
        }
      } else {
        return oldValue
      }
    }
  )

  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      desc: true,
      id: 'lastOperation',
    },
  ])

  const [columnVisibility, setColumnVisibility] =
    useClientStorage<MRT_VisibilityState>('columnsVisibility', {})

  const [rowCount, setRowCount] = useState(0)

  const [projectStateFilters, setProjectStateFilters] = useClientStorage<
    ProjectStateAsString[]
  >('project-state-filters', [])

  const [filterValue, setFilterValue] = useState<string>(currentQuery)

  const getProjectList = useCallback(async () => {
    try {
      try {
        controller.CancelRequests()
      } catch (err) {
        console.info('canceled getProjects request - another request was made')
      }

      const resp = await controller.GetProjectsListPaged(
        onlyMyProjects,
        showCanceledProjects ||
          projectStateFilters.includes(ProjectStateAsString.CANCELLED),
        {
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          search: filterValue,
          orderBy: sorting?.[0]?.id as keyof ProjectSummaryDto,
          orderDirection: sorting?.[0]?.desc ? 'desc' : 'asc',
        },
        projectStateFilters
      )

      if (resp) {
        setCurrentQuery(filterValue)
        setProjectList((curr) => resp?.details || curr)
        setRowCount((curr) => resp?.totalCount || curr)
      }
    } catch (err) {
      ShowException(err)
    }
  }, [
    ShowException,
    controller,
    filterValue,
    onlyMyProjects,
    pagination.pageIndex,
    pagination.pageSize,
    projectStateFilters,
    setCurrentQuery,
    showCanceledProjects,
    sorting,
  ])

  const handleDeleteProject = useCallback(
    async (projectId: string, projectStatus: ProjectStateAsString) => {
      try {
        dispatch(
          deleteProjects({
            requests: [{ projectId: projectId, projectStatus: projectStatus }],
            successCallback: () => {
              setProjectList(projectList.filter((x) => x.id !== projectId))
            },
          })
        )
      } catch (err) {
        ShowException(err)
      }
    },
    [ShowException, dispatch, projectList]
  )

  const handleDeleteSelectedProjects = useCallback(
    async (table: MRT_TableInstance<ProjectSummaryDto>) => {
      const selectedProjectsIds = table.getState().rowSelection
      const deleteRequests = Object.keys(selectedProjectsIds).map((x) => {
        return {
          projectId: x,
          projectStatus: projectList
            .find((p) => p.id === x)
            ?.status.toUpperCase() as ProjectStateAsString,
        }
      })

      dispatch(
        deleteProjects({
          requests: deleteRequests,
          successCallback: (projectIds) => {
            setProjectList(
              projectList.filter((x) => !projectIds.includes(x.id))
            )
            table.setState((state) => ({ ...state, rowSelection: {} }))
          },
        })
      )
    },
    [dispatch, projectList]
  )

  const handleChangeOnlyMyProjects = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setOnlyMyProjects(checked)
    },
    [setOnlyMyProjects]
  )

  const handleProjectStateFilter = useCallback(
    (selectedProjectStates: ProjectStateAsString[]) => {
      setProjectStateFilters(selectedProjectStates)
    },
    [setProjectStateFilters]
  )

  useEffect(() => {
    getProjectList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectList])

  useEffect(() => {
    let lastTimeoutTotalSeconds = 0

    const getTotalSeconds = () => {
      return Math.floor(Date.now() / 1000)
    }

    SignalRService.GetHub().then((hub) => {
      let timeout: NodeJS.Timeout

      hub.registerHandler('onProjectsListUpdates', () => {
        clearTimeout(timeout)

        const currentTimeoutTotalSeconds = getTotalSeconds()

        if (lastTimeoutTotalSeconds === 0) {
          // run the first time
          getProjectList()
          lastTimeoutTotalSeconds = currentTimeoutTotalSeconds
          return
        }

        if (currentTimeoutTotalSeconds - lastTimeoutTotalSeconds > 20) {
          // run if the last update was more than 20 seconds ago
          getProjectList()
          lastTimeoutTotalSeconds = currentTimeoutTotalSeconds
          return
        } else {
          timeout = setTimeout(() => {
            getProjectList()
            lastTimeoutTotalSeconds = getTotalSeconds()
          }, 10_000)
        }
      })

      hub.JoinGroup(`${partyId}_project_list`, () => {
        console.info('connected')
      })
    })

    return () => {
      SignalRService.GetHub().then((hub) => {
        hub.LeaveGroup(`${partyId}_project_list`, () => {
          console.info('disconnected from project list')
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyId])

  const handleFilterChange = async (filterValue: string) => {
    try {
      setFilterValue(filterValue)
      controller.CancelRequests()
    } catch (err) {
      ShowException(err)
    }
  }

  const tableOptions = useLocalizedMaterialReactTable({
    columns: _columns,
    data: projectList,
    getRowId: (row) => row.id,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
    },
    state: {
      isLoading:
        filterValue !== currentQuery ||
        (loading['GetProjectsListPaged'] && !projectList?.length),
      globalFilter: filterValue,
      columnVisibility: columnVisibility,
      pagination: pagination,
      sorting: sorting,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20],
    },
    manualSorting: true,
    onSortingChange: setSorting,
    rowCount: rowCount,
    enableFilterMatchHighlighting: false,
    enableMultiSort: false,
    enableClickToCopy: true,
    enableStickyHeader: true,
    enableGrouping: true,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    layoutMode: 'grid',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onColumnVisibilityChange: (updater: any) => {
      const value = updater()

      if (Object.keys(value)[0] === 'projectReference') {
        return
      }

      setColumnVisibility(updater as SetStateAction<MRT_VisibilityState>)
    },
    manualPagination: true,
    onPaginationChange: (pagination) => {
      setPagination(pagination)
    },
    manualFiltering: true,
    onGlobalFilterChange: (filterValue) => {
      handleFilterChange(filterValue)
    },
    renderRowActions: (props) => {
      return (
        <RowActionsComponent
          {...props}
          handleDeleteProject={handleDeleteProject}
        />
      )
    },
    enableGlobalFilter: false,
    renderTopToolbarCustomActions: (toolbarProps) => {
      return (
        <div style={{ width: '100%' }}>
          {Object.keys(toolbarProps.table.getState().rowSelection || {})
            ?.length ? (
            <div>
              <LocalizedButton
                translationKey="common:delete-selected-projects"
                color="error"
                variant="contained"
                onClick={() => handleDeleteSelectedProjects(toolbarProps.table)}
              />
            </div>
          ) : null}
        </div>
      )
    },
    renderToolbarInternalActions: (toolbarProps) => {
      return (
        <TopToolbar
          {...toolbarProps}
          getProjectList={getProjectList}
          handleChangeOnlyMyProjects={handleChangeOnlyMyProjects}
          handleDeleteSelectedProjects={() =>
            handleDeleteSelectedProjects(toolbarProps.table)
          }
          loading={loading}
          onlyMyProjects={onlyMyProjects}
          showCanceledProjects={showCanceledProjects}
          setShowCanceledProjects={setShowCanceledProjects}
          onProjectStateChange={handleProjectStateFilter}
          selectedProjectStates={projectStateFilters}
        />
      )
    },

    muiTableBodyRowProps: (rowProps) => {
      return {
        onClick: (e) => {
          dispatch(fullUnloadActiveProject())

          if (e.ctrlKey || e.metaKey) {
            window.open(
              `${window.location.origin}/app/project/${rowProps.row.original.id}`,
              '_blank',
              'rel=noopener noreferrer'
            )
          } else {
            navigate(`/app/project/${rowProps.row.original.id}`)
          }
        },
        sx: {
          cursor: 'pointer',
        },
      }
    },
    muiTableContainerProps: (tableContainerProps) => {
      const height =
        tableContainerProps.table.getIsSomeRowsSelected() ||
        tableContainerProps.table.getIsAllRowsSelected()
          ? 'calc(100vh - 408px)'
          : 'calc(100vh - 308px)'

      return {
        style: {
          height: height,
        },
      }
    },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      InputProps: {
        sx: {
          '& input': {
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '20vw',
              xl: '20vw',
            },
          },
        },
      },
    },
    enableColumnDragAndDrop: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        visibleInShowHideMenu: false,
      },
      'mrt-row-select': {
        visibleInShowHideMenu: false,
      },
    },
  })

  return (
    <div>
      <MaterialReactTable table={tableOptions} />
    </div>
  )
}
