import { MenuItem, Select, Typography } from '@mui/material'
import { SpinningIcon } from 'components/Common/Spinner/Spinning'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ResourceMatchType,
  ResourceSummaryDto,
  WorkingStepDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import { saveBomItemResource } from '../store/asyncActions/saveBomItemResource'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'
import { bomItemActivitiesSelectors } from '../store/selectors/activities/bomItemActivitiesSelectors'

type Props = {
  bomItemPointers: BomItemPointer[]
  workingStep: WorkingStepDto
  onFocus?: () => void
  onBlur?: () => void
  disableLabel?: boolean
  disableUnderline?: boolean
  disableMatchDescription?: boolean
  disableSelectMaterialError?: boolean
  fullWidth?: boolean
  typographyClassname?: string
}

const useStyles = makeStyles({ name: 'ResourceSelector' })(() => {
  return {
    typographyDefault: {
      width: '100%',
      textAlign: 'center',
      maxWidth: '220px',
      whiteSpace: 'pre-wrap',
      display: 'inline-block',
    },
    typograophyFullWidth: {
      maxWidth: '100%',
    },
  }
})

export const WorkingStepResourceSelector = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes, cx } = useStyles()

  const materialSummary = useAppSelector(
    bomItemActivitiesSelectors.articlesPerWorkingStep(
      props.bomItemPointers[0],
      props.workingStep.primaryWorkingStep
    ),
    isEqual
  )

  const [open, setOpen] = useState(false)

  const projectEditable = useAppSelector(ProjectEditableStateSelector)

  const { partyId, projectId } = useOrganizationAndProjectContext()
  const { controller, loading, error } = useAppController(
    () => new BomItemController(partyId, projectId)
  )

  const [resourceOptions, setResourceOptions] =
    useState<Array<ResourceSummaryDto>>()

  const handleChange = (e) => {
    dispatch(
      saveBomItemResource({
        bomItemPointers: props.bomItemPointers,
        workingStepType: props.workingStep.primaryWorkingStep,
        resource: resourceOptions.find((x) => x.id === e.target.value),
        controllerInstance: controller,
      })
    )
  }

  const getHelperText = useCallback(() => {
    let helperText =
      error[`get-selectable-resources-${props.bomItemPointers[0]}`]?.message

    if (
      props.bomItemPointers[0]?.type === BomItemType.partType &&
      !materialSummary?.id &&
      !props.disableSelectMaterialError
    ) {
      helperText = t(
        'project:select-material-before-selecting-resource',
        'material group or quality must be selected before selecting a resource'
      )
    }

    if (!helperText && !props.disableMatchDescription) {
      helperText = t(props.workingStep.resource?.matchDescription)
    }

    return helperText
  }, [
    error,
    materialSummary?.id,
    props.bomItemPointers,
    props.disableMatchDescription,
    props.disableSelectMaterialError,
    props.workingStep.resource?.matchDescription,
    t,
  ])

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      if (
        !projectEditable ||
        props.workingStep.resource?.matchType ===
          ResourceMatchType.OneCapableResource
      ) {
        return
      }

      if (!open) {
        controller
          .GetBomItemSelectableResources(
            props.bomItemPointers[0],
            props.workingStep.primaryWorkingStep
          )
          .then((summaries) => {
            setResourceOptions(
              summaries.filter((x) => x.id !== props.workingStep.resource?.id)
            )
          })
      }

      setOpen(!open)
    },
    [
      controller,
      open,
      projectEditable,
      props.bomItemPointers,
      props.workingStep.primaryWorkingStep,
      props.workingStep.resource?.id,
      props.workingStep.resource?.matchType,
    ]
  )

  return (
    <span>
      {props.workingStep.resource?.id && (
        <Select
          value={
            props.workingStep.resource?.id ?? ''
            // workingStep.resource?.id &&
            // workingStep.resource?.id !== '00000000-0000-0000-0000-000000000000'
            //   ? workingStep.resource?.id
            //   : ''
          }
          required
          onChange={handleChange}
          label={props.disableLabel ? null : t('project:resource')}
          open={open}
          fullWidth
          onClick={handleClick}
          onClose={() => {
            setOpen(false)
            setResourceOptions([])
          }}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disableUnderline={props.disableUnderline}
          disabled={
            props.workingStep.resource?.matchType ===
              ResourceMatchType.OneCapableResource ||
            !projectEditable ||
            (props.workingStep.resource?.id &&
              !props.workingStep.resource?.name)
          }
          renderValue={() => {
            if (
              props.workingStep.resource?.id &&
              (!props.workingStep.resource.name ||
                props.workingStep.resource.name === '')
            ) {
              return (
                <Typography>
                  <SpinningIcon />
                </Typography>
              )
            }

            return (
              <Typography
                variant={props.workingStep.resource?.id ? 'caption' : 'caption'}
                color={
                  props.workingStep.resource?.id ? 'textPrimary' : 'GrayText'
                }
                className={cx(
                  classes.typographyDefault,
                  props.typographyClassname,
                  {
                    [classes.typograophyFullWidth]: props.fullWidth,
                  }
                )}
              >
                {props.workingStep.resource?.name ||
                  t('project:select-resource', 'select a resource')}
              </Typography>
            )
          }}
        >
          {loading[
            `get-selectable-resources-${props.bomItemPointers[0]}-${props.workingStep.primaryWorkingStep}`
          ] && (
            <MenuItem value="">
              <Typography variant="caption">{`${t(
                'common:loading'
              )}...`}</Typography>
            </MenuItem>
          )}
          {
            // this one is here to not have the @mui runtime warning
            // about current value not being in the options.
            // notice the display: none
            props.workingStep.resource?.id &&
              props.workingStep.resource?.id !==
                '00000000-0000-0000-0000-000000000000' && (
                <MenuItem
                  value={props.workingStep.resource?.id}
                  style={{ display: 'none' }}
                  sx={{
                    borderBottom: (theme) =>
                      `2px solid ${theme.palette.divider}`,
                    gap: '8px',
                  }}
                >
                  <Typography variant="caption">
                    {props.workingStep.resource?.name}
                  </Typography>
                </MenuItem>
              )
          }
          {resourceOptions
            ?.filter((x) => x.id !== props.workingStep?.resource?.id)
            .map((x) => {
              return (
                <MenuItem key={x.id} value={x.id}>
                  <Typography variant="caption">{x.name}</Typography>
                </MenuItem>
              )
            })}
        </Select>
      )}
      <Typography variant="caption" color="GrayText">
        {getHelperText()}
      </Typography>
    </span>
  )
}
